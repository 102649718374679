import React from "react";
import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";

function Returns() {
  return (
    <div>
      <Navbar />
      <section
        class=" max-w-screen-xl min-h-[78vh] px-4 py-6 mx-auto  mb-14"
       
      >
        <p class="mb-2 md:mb-5 md:mt-10  text-[20px] md:text-[22px]  text-[#314387] font-bold font-heading">
          Return and Refund Policy for Alpxel:
        </p>

        <p className="md:text-[16px] text-[14px] text-gray-700 text-justify">
          At Alpxel, we want you to be completely satisfied with your
          purchase. If for any reason you are not entirely pleased with your
          order, we offer a straightforward return and refund policy to ensure a
          hassle-free experience.
        </p>

        <p class="mb-2 md:mb-2 md:mt-10  text-[20px] md:text-[22px] text-[#314387] font-bold font-heading">
          Eligibility for Returns:
        </p>
        <p className="md:text-[16px] text-[14px] text-gray-700 text-justify">
          You may request a return within 2 days of receiving your Alpxel
          products. To be eligible for a return, the item must be in its
          original packaging, unused, and in the same condition as when you
          received it.
        </p>

        <p class="mb-2 md:mb-2 md:mt-10  text-[20px] md:text-[22px] text-[#314387] font-bold font-heading">
          Initiating a Return:
        </p>
        <p className="md:text-[16px] text-[14px] text-gray-700 text-justify">
          To initiate a return, please contact our customer support team via
          reachus@alpxel.com / 8296089323 within the specified return period. Provide your order
          number, the reason for the return, and any relevant details. Our team
          will guide you through the return process and provide you with a
          return authorization if eligible.
        </p>

        <p class="mb-2 md:mb-2 md:mt-10  text-[20px] md:text-[22px] text-[#314387] font-bold font-heading">
          Return Shipping:
        </p>
        <p className="md:text-[16px] text-[14px] text-gray-700 text-justify">
          Customers are responsible for the return shipping costs. We recommend
          using a trackable shipping method to ensure the safe return of the
          products. Alpxel is not liable for returns that are lost or damaged
          during transit.
        </p>

        <p class="mb-2 md:mb-2 md:mt-10  text-[20px] md:text-[22px] text-[#314387] font-bold font-heading">
          Refund Process:
        </p>
        <p className="md:text-[16px] text-[14px] text-gray-700 text-justify">
          Once we receive and inspect the returned items, we will notify you of
          the status of your refund. If your return is approved, a refund will
          be processed to your original payment method. Please allow 14
          business days for the refund to reflect in your account.
        </p>

        <p class="mb-2 md:mb-2 md:mt-10  text-[20px] md:text-[22px] text-[#314387] font-bold font-heading">
          Exclusions and Conditions:
        </p>
        <ul className="list-disc pl-6 text-gray-700">
          <li>
            Used or opened products are not eligible for returns unless they are
            damaged or defective.
          </li>
          <li>Shipping costs are non-refundable.</li>
          <li>
            Returns are subject to inspection; if products are deemed unfit for
            return, the refund request may be denied.
          </li>
          <li>
            In the case of damaged or defective products, please provide
            photographic evidence along with your return request.
          </li>
        </ul>

        <p class="mb-2 md:mb-2 md:mt-10  text-[20px] md:text-[22px] text-[#314387] font-bold font-heading">
          Damaged or Defective Products:
        </p>
        <p className="md:text-[16px] text-[14px] text-gray-700 text-justify">
          If you receive a damaged or defective product, please contact us
          immediately. We will arrange for a replacement or a refund, depending
          on your preference.
        </p>

        <p class="mb-2 md:mb-2 md:mt-10  text-[20px] md:text-[22px] text-[#314387] font-bold font-heading">
          Cancellations:
        </p>
        <p className="md:text-[16px] text-[14px] text-gray-700 text-justify">
          Order cancellations can be accommodated if the order has not been
          processed for shipping. If the order has already been shipped, the
          return and refund policy applies.
        </p>
        <p className="md:text-[16px] text-[14px] text-gray-700 text-justify">
          We strive to ensure that our customers have a positive experience with
          Alpxel products. If you have any questions or concerns about our
          return and refund policy, please don't hesitate to contact our
          customer support team.
        </p>
      </section>
      <Footer />
    </div>
  );
}

export default Returns;
