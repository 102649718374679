import React from "react";
import Section1 from "./Section1";
import Section2 from "./Section2";
// import Section4 from "./Section4";
// import Section3 from "./Section3";
import Section5 from "./Section5";
import Footer from "../../common/Footer";
import Section6 from "./Section6";

import Navbar from "../../common/Navbar";
import Section7 from "./Section7";
// import Section8 from "./Section8";
import Section9 from "./Section9";
// import VideoModal from "./VideoModal";
import Install from "../pwa/install";
import EcommerceComponent from "./EcommerceComponent";

function index() {
  return (
    <div className="">
      <Navbar />
      <Section1 />
      <EcommerceComponent />
      <Install />
      <Section2 />
      {/* <VideoModal /> */}
      <Section9 />
      <Section5 />
      <Section7 />

      {/* <Section4/> */}
      {/* <Section3/> */}
      {/* <Section8/> */}
      <Section6 />

      <Footer />
    </div>
  );
}

export default index;
