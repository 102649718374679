import React, { useEffect, useState } from "react";
import Footer from "../../common/Footer";
import { Interweave } from "interweave";
import Navbar from "../../common/Navbar";
import { useParams } from "react-router-dom";
import {
  fetchAllProducts,
  fetchOneProduct,
  productSelector,
} from "../../api/products";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addCartProducts, cartProductSelector } from "../../api/cartSlice";
import Magnifier from "react-magnifier";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import "./index.css";

function Individualprod() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const { currentProduct } = useSelector(productSelector);
  const { allproducts } = useSelector(productSelector);
  const { cartProducts } = useSelector(cartProductSelector);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [featuredImage, setFeaturedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    dispatch(fetchOneProduct(id));
  }, [id]);

  useEffect(() => {
    dispatch(fetchAllProducts());
  }, [dispatch]);

  useEffect(() => {
    setFeaturedImage(currentProduct?.featured_image);
  }, [currentProduct]);

  const changeFeaturedImage = (image) => {
    // Update the currentImageIndex when an image is clicked
    const newIndex = currentProduct?.product_images.indexOf(image);

    setCurrentImageIndex(newIndex);
    setFeaturedImage(image);
  };

  const exist = cartProducts.findIndex((isthere) => isthere._id === id);

  useEffect(() => {
    const exist = cartProducts.findIndex((isthere) => isthere._id === id);
  }, [cartProducts]);

  const scroolUp = () => {
    window.scrollTo(0, 0);
  };
  const onAdd = (currentProduct) => {
    let newdata = { ...currentProduct };

    dispatch(addCartProducts(newdata));
    scroolUp();
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const toggleAccordion1 = () => {
    setIsOpen1(!isOpen1);
  };
  const toggleAccordion2 = () => {
    setIsOpen2(!isOpen2);
  };

  return (
    <div>
      <Navbar />
      <div className="bg-slate-50">
        <section className="pt-4 md:pt-10 md:mx-20   bg-blueGray-100 rounded-b-10xl overflow-hidden  ">
          <div className="container   px-4 mx-auto">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full   lg:w-1/2 px-4 mb-2 md:mb-16 lg:mb-0">
                <div className=" -mx-4   items-center justify-between lg:justify-start lg:items-start xl:items-center">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={1}
                    navigation={true}
                    loop={true}
                    pagination={{ clickable: true }}
                    // autoplay={{
                    //   delay: 3500,
                    //   disableOnInteraction: false,
                    // }}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 1,
                        spaceBetween: 30,
                      },
                      1000: {
                        slidesPerView: 1,
                        spaceBetween: 30,
                      },
                    }}
                  >
                    {currentProduct?.product_images.map((image, index) => (
                      <SwiperSlide key={index}>
                        <Magnifier
                          className="rounded-md"
                          src={image}
                          alt={`Product Image ${index + 1}`}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  {/* <div className="flex items-center md:gap-4 md:mr-60 justify-center">
                    {currentProduct?.product_images.map((image, index) => (
                      <a
                        key={index}
                        className={`h-30 block mb-4 ${
                          index !== currentImageIndex
                            ? "mr-2 sm:mr-0"
                            : "mr-4 sm:mr-0"
                        } ${
                          index === 2
                            ? "rounded-xl border-2 border-blueGray-500"
                            : ""
                        }`}
                        href="#"
                        onClick={() => changeFeaturedImage(image)}
                      >
                        <img
                          className="h-16 w-16 rounded-lg "
                          src={image}
                          alt={`Product Image ${index + 1}`}
                        />
                      </a>
                    ))}
                  </div> */}
                </div>
              </div>
              <div className="w-full lg:w-1/2 px-4">
                <div className="max-w-md mb-6">
                  <span className="text-xs text-gray-700 ml-6 tracking-wider">
                    #{currentProduct?.product_id}
                  </span>
                  <h2 className="mt-2 mb-4 text-3xl ml-6 text-[#314387] md:text-3xl lg:text-4xl font-heading font-medium">
                    {currentProduct?.product_name}
                  </h2>
                  <p class="text-xl font-bold font-heading text-gray-700 ">
                    <span class="  mb-6 px-2 py-1 text-2xl font-bold font-heading    text-red-500">
                      -{currentProduct?.discount}%
                    </span>
                    <span>Rs {currentProduct?.total_price}</span>
                  </p>
                  <span class="text-sm text-gray-500 font-semibold ml-6 font-heading line-through">
                    M.R.P: {currentProduct?.price}
                  </span>
                  {/* <p className="text-lg text-gray-400">
                  Five easy pieces that will change the way you cook in your
                  oven.
                </p> */}
                </div>

                <div className="ml-4">
                  <button
                    className={` w-80 md:w-[600px] pl-6 lg:pl-6 pr-6 py-2  leading-7 rounded-2xl border-2 border-blueGray-200 hover:border-blueGray-300`}
                    onClick={toggleAccordion}
                  >
                    <h3 className="text-lg font-heading  text-[#314387] flex justify-between text-left font-medium">
                      Product details
                      <svg
                        width="12"
                        height="8"
                        className="mt-4"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.4594 0.289848C10.8128 -0.096616 11.3841 -0.096616 11.7349 0.289848C12.0871 0.676312 12.0897 1.30071 11.7349 1.68718L6.63794 7.21015C6.28579 7.59662 5.71584 7.59662 5.36108 7.21015L0.264109 1.68718C-0.0880363 1.30215 -0.0880363 0.676312 0.264109 0.289848C0.617558 -0.096616 1.18882 -0.096616 1.53966 0.289848L6.00147 4.81927L10.4594 0.289848Z"
                          fill="black"
                        ></path>
                      </svg>
                    </h3>

                    {isOpen && (
                      <div className="w-full text-gray-700   py-4  text-justify">
                        {/* Content to display when the accordion is open */}
                        <Interweave content={currentProduct?.description} />
                      </div>
                    )}
                  </button>
                </div>

                {currentProduct?.stock_qty <= 0 ? (
                  <div className="flex flex-wrap my-8 justify-start items-start mr-36 md:mr-0">
                    <div className="w-full md:w-2/3 px-2 mb-2 text-center md:mb-0">
                      <div className="py-2 px-10 leading-8 font-heading font-medium tracking-tighter text-base text-gray-700 text-center bg-gray-300 rounded-full cursor-not-allowed">
                        Out of Stock
                      </div>
                    </div>
                  </div>
                ) : exist === -1 ? (
                  <div className="flex flex-wrap my-8 justify-start items-start mr-36 md:mr-0">
                    <div className="w-full md:w-2/3 px-2 mb-2 text-center md:mb-0">
                      <a
                        className="inline md:block py-2 px-10 leading-8 font-heading font-medium tracking-tighter text-base text-white text-center bg-[#F15925] focus:ring-2 focus:ring-[#e47550] focus:ring-opacity-50 hover:bg-[#fa8159] rounded-full"
                        onClick={() => onAdd(currentProduct)}
                      >
                        Add to Bag
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-wrap my-8 justify-start items-start mr-36 md:mr-0">
                    <div className="w-full md:w-2/3 text-center px-2 mb-2 md:mb-0">
                      <div className="inline md:block py-2 px-16 leading-8 font-heading font-medium tracking-tighter text-base text-white text-center bg-[#F15925] focus:ring-2 focus:ring-[#e47550] focus:ring-opacity-50 hover:bg-[#fa8159] rounded-full cursor-not-allowed">
                        In Bag
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className=" max-w-screen-xl min-h-[78vh] px-4 py-4 mx-auto text-justify ">
        <div className="md:mx-20">
          <h2 className="mb-2 md:mb-5 md:mt-10 text-2xl md:text-4xl text-[#314387]  font-bold font-heading">
            Overview
          </h2>
          <p className="text-base md:text-lg text-gray-700 ">
            Ditch the chemicals with Alpxel stainless steel cookware. Our
            complete set has everything you’ll need for healthy cooking in your
            kitchen, starting from a saucepan, Fry pan and casseroles and
            looking good while doing so.{" "}
          </p>

          <h2 className="mb-2 md:mb-5 mt-6 md:mt-10 text-2xl md:text-4xl text-[#314387]  font-bold font-heading">
            Details
          </h2>
          <p className="text-base md:text-lg  text-gray-700 ">
            Whether your new to cooking for looking for an upgrade, our
            specifically designed Alpxel cookware to start you off on the right
            path.{" "}
          </p>
          <p className="text-base md:text-lg text-gray-700 ">
            {" "}
            Our Alpxel stainless stell cookware is constructed from 7 layers of
            metal for improved heat conduction. This acts as a non-stick by
            preventing any stuck-on messes and ensures the 7-ply construction
            cleans up in seconds, while rolled rims easily pour without
            spilling.{" "}
          </p>
          <p className="text-base md:text-lg text-gray-700 ">
            These 7-ply of heat conducting material creates an even- heating
            stainless base with a frictionless cooking surface that’s durable
            and easy to clean.{" "}
          </p>
        </div>
        <div className="md:mx-20">
          <button
            className={` w-80 md:w-[900px] pl-6 lg:pl-12 pr-6 py-1 mt-10  leading-7 rounded-2xl border-2 border-blueGray-200 hover:border-blueGray-300`}
            onClick={toggleAccordion1}
          >
            <h3 className="text-xl font-heading text-left  flex justify-between font-medium text-[#314387]">
              Cookware care
              <svg
                width="12"
                height="8"
                className="mt-4"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.4594 0.289848C10.8128 -0.096616 11.3841 -0.096616 11.7349 0.289848C12.0871 0.676312 12.0897 1.30071 11.7349 1.68718L6.63794 7.21015C6.28579 7.59662 5.71584 7.59662 5.36108 7.21015L0.264109 1.68718C-0.0880363 1.30215 -0.0880363 0.676312 0.264109 0.289848C0.617558 -0.096616 1.18882 -0.096616 1.53966 0.289848L6.00147 4.81927L10.4594 0.289848Z"
                  fill="black"
                ></path>
              </svg>
            </h3>

            {isOpen1 && (
              <div className="w-full  py-4 bg-blueGray-200">
                {/* Content to display when the accordion is open */}
                <ul className="list-disc text-gray-700  text-left text-base">
                  <li>
                    <b>Before first use:</b> Be sure to wash the pan before the
                    first use and to clean thoroughly between uses..
                  </li>
                  <li>
                    <b>For Browning, Sauteing and Searing:</b> Start with food
                    at room temperature. Dry food before cooking to prevent
                    splattering. Preheat pan on low to moderate heat before
                    cooking. Water will dance on pan when ready. Add few
                    teaspoons oil, once the oil is hot add food to the pan. You
                    will see a faint vapor when the oil is ready. Food should
                    sizzle when added to the pan. Allow the food to cook. On
                    stainless steel it would be ready to turn when it no longer
                    sticks t the pan.{" "}
                  </li>
                  <li>
                    <b>For boiling and Steaming:</b> This is the only occasion
                    when high heat should be heated.
                  </li>
                  <li>
                    <b>Caution:</b> With the exception of preheating, empty pans
                    should not be left on a hot burner as it can cause damage.
                    Overheating can cause brown or blue stains to appears. To
                    avoid small white dots or pits from forming in your
                    cookware, bring liquids to boil or wait until food starts to
                    cook before adding salt. Pitting does not interfere with
                    cooking performance but can diminish the beauty of your
                    pan’s interior.
                  </li>
                </ul>
              </div>
            )}
          </button>

          <button
            className={` w-80 md:w-[900px] pl-6 lg:pl-12 pr-6 py-1 mt-2  leading-7 rounded-2xl border-2 border-blueGray-200 hover:border-blueGray-300`}
            onClick={toggleAccordion2}
          >
            <h3 className="text-xl font-heading flex justify-between text-left font-medium text-[#314387]">
              Cookware Cleaning
              <svg
                width="12"
                height="8"
                className="mt-4"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.4594 0.289848C10.8128 -0.096616 11.3841 -0.096616 11.7349 0.289848C12.0871 0.676312 12.0897 1.30071 11.7349 1.68718L6.63794 7.21015C6.28579 7.59662 5.71584 7.59662 5.36108 7.21015L0.264109 1.68718C-0.0880363 1.30215 -0.0880363 0.676312 0.264109 0.289848C0.617558 -0.096616 1.18882 -0.096616 1.53966 0.289848L6.00147 4.81927L10.4594 0.289848Z"
                  fill="black"
                ></path>
              </svg>
            </h3>

            {isOpen2 && (
              <div className="w-full  py-4 bg-blueGray-200">
                {/* Content to display when the accordion is open */}
                <ul className="list-disc text-left text-gray-700  text-base ">
                  <li>
                    To keep your Alpxel Cookware brand new, we recommend washing
                    your cookware with soap and sponge.
                  </li>
                  <li>
                    Allow the cookware to cool prior to cleaning. Rinse off any
                    excess food with warm water. Soak in warm, soapy water, then
                    wash with sponge or soft cloth. Use a nylon scouring pad for
                    more cleaning. Rinse with warm water and dry immediately to
                    prevent spotting.
                  </li>
                  <li>
                    <b>For burnt food: </b> Sprinkle the surface generously with
                    baking soda, add water and bring to a boil. Use a wooden
                    spoon to loosen the food particles from the surface.
                  </li>
                  <li>
                    <b>
                      For tough to Clean Spots or Marks, like burnt fat, protein
                      shadows, and charred food:
                    </b>{" "}
                    Clean with a nonabrasive, non-chlorine cleanser.
                  </li>
                  <li>
                    <b>For Discoloration like blue or rainbow colouring:</b>Wipe
                    the pan with sponge or soft cloth and white vinegar.
                  </li>
                  <li>
                    <b>For cloudy white hard water spots:</b>Remove by boiling a
                    1:1 white vinegar to water solution.
                  </li>
                  <li>
                    <b>Caution:</b>: Do not use cleaners, steel wool, steel
                    scouring pads, harsh detergents or detergents containing
                    bleach or peroxide, which can damage the pan. Nylon
                    scrubbing pads can be used on stainless steel surfaces only.
                    Never place a hot pan under cold water, as it causes
                    warping.
                  </li>
                </ul>
              </div>
            )}
          </button>
        </div>
      </section>
      <div className="bg-gray-50 mb-16 md:mb-0">
        <div className="my-2 md:mx-20  ">
          <section class="relative py-2 lg:pt-10  overflow-x-hidden">
            <div class="relative container mx-auto px-4">
              <h2 class="mb-4 text-2xl md:text-3xl text-[#314387] font-bold font-heading">
                Similar Products
              </h2>
              <Swiper
                slidesPerView={1} // For mobile devices, show 1 slide per view
                spaceBetween={10} // Adjust the space between slides as needed
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: false,
                }}
                loop={true}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
                breakpoints={{
                  // Define breakpoints for different screen sizes
                  640: {
                    slidesPerView: 2, // Show 2 slides per view when screen width is 640px or more
                    spaceBetween: 20, // Adjust the space between slides as needed
                  },
                  768: {
                    slidesPerView: 3, // Show 3 slides per view when screen width is 768px or more
                    spaceBetween: 30, // Adjust the space between slides as needed
                  },
                  1000: {
                    slidesPerView: 3, // Show 3 slides per view when screen width is 768px or more
                    spaceBetween: 30, // Adjust the space between slides as needed
                  },
                }}
              >
                <div class="md:flex md:flex-wrap -mx-3">
                  {allproducts.map((item) => {
                    return (
                      <SwiperSlide>
                        <div class=" md:w-full px-3 md:mb-16 bg-gray-50 lg:mb-0">
                          <div class="block mb-4" href="">
                            <div class="relative">
                              <img
                                class="w-full h-96 object-cover rounded-lg"
                                src={item?.featured_image}
                                alt=""
                              />
                            </div>
                            <div class="mt-6">
                              <div class="mb-2">
                                <h3 class="mb-3 text-lg text-[#314387]  font-bold font-heading">
                                  {item?.product_name}
                                </h3>

                                <p class="text-lg font-bold font-heading text-gray-800 ">
                                  <span class="  mb-6 px-2 py-1 text-2xl font-bold font-heading    text-red-500">
                                    -{item?.discount}%
                                  </span>
                                  <span>Rs {item?.total_price}</span>
                                </p>
                                <span class="text-sm text-gray-500 font-semibold font-heading line-through">
                                  M.R.P: {item?.price}
                                </span>
                              </div>
                            </div>
                          </div>
                          {item?.stock_qty > 0 ? (
                          <Link
                            class="inline-block bg-[#F15925] hover:bg-[#F15926] text-white font-bold font-heading py-2 px-10 rounded-full transition duration-200"
                            to={`/individualpro/${item?._id}`}
                          >
                            Buy Now
                          </Link>
                        ) : (
                          <button
                            class="inline-block bg-gray-300 text-gray-700 font-bold font-heading py-2 px-10 rounded-full cursor-not-allowed"
                            disabled
                          >
                            Out of Stock
                          </button>
                        )}
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </div>
              </Swiper>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Individualprod;
