import React, { useEffect, useState } from "react";
import P1 from "../assets/p-1.jpeg";
import Footer from "./Footer";

import Navbar from "./Navbar";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchlogin, authenticateSelector } from "../api/authSlice";
import { FaEye, FaEyeSlash } from "react-icons/fa";


function Login() {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { isAuthenticate } = useSelector(authenticateSelector);
  const [showPassword, setShowPassword] = useState(false);


  const [formData, setFormData] = useState({
    phone_number: "",
    password: "",
  });

  useEffect(() => {
    if (isAuthenticate) {
      return nav("/");
    }
  }, [isAuthenticate]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    dispatch(fetchlogin(formData));
  };

  const toggleShowPassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };
  

  return (
    <div>
      <Navbar className="sticky top-0" />
      <section className="relative    pb-20 md:pb-32 overflow-hidden">
        <div className="relative h-full w-full pt-12 sm:pt-28 mx-auto">
          <img
            className="absolute top-0 left-0   "
            src="https://wallpapercave.com/wp/4lImLyA.jpg"
            alt=""
          />
          <div className="relative  px-4 mx-auto ">
            <div className="max-w-lg md:max-w-md py-6 px-6 xs:px-12 lg:px-10 mx-auto bg-white rounded-xl shadow-lg">
              <h3 className="font-heading text-2xl md:text-4xl text-gray-700 font-semibold mb-4">
                Login to your account
              </h3>
              <p className="text-lg text-gray-500 mb-10">
                Greetings on your return! We kindly request you to enter your
                details.
              </p>
              <form action="" onSubmit={handleSubmit}>
                {/* <div className="mb-6">
                  <label className="block mb-1.5 text-sm text-gray-900 font-semibold" htmlFor="">Name</label>
                  <input className="w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 focus:border-purple-500 focus:outline-purple rounded-lg" type="text" placeholder="Stefano Bojarski" />
                </div> */}
                <div className="mb-6">
                  <label
                    className="block mb-1.5 text-sm text-gray-900 font-semibold"
                    htmlFor=""
                  >
                    Phone Number
                  </label>
                  <input
                    value={formData.phone_number}
                    onChange={handleInputChange}
                    required
                    className="w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 focus:border-purple-500 focus:outline-purple rounded-lg"
                    type="text"
                    placeholder="Phone Number"
                    name="phone_number"
                  />
                </div>
                <div className="mb-6">
                  <label
                    className="block mb-1.5 text-sm text-gray-900 font-semibold"
                    htmlFor=""
                  >
                    Password
                  </label>
                  <div className="relative">
                    <input
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                      className="w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 focus:border-purple-500 focus:outline-purple rounded-lg"
                      type={showPassword ? "number" : "password"}
                      placeholder=""
                      name="password"
                    />
                       <button
                      onClick={toggleShowPassword}
                      className="absolute top-1/2 right-0 mr-3 transform -translate-y-1/2 inline-block hover:scale-110 transition duration-100"
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                </div>
                {/* <div className="flex mb-6 items-center">
                  <input type="checkbox" value="" id="" />
                  <label className="ml-2 text-xs text-gray-800" htmlFor="">Remember me</label>
                </div> */}
                <button
                  className="relative group block w-full mb-6 py-3 px-5 text-center text-sm font-semibold text-orange-50 bg-[#F15925] rounded-full overflow-hidden"
                  type="submit"
                  htmlType="submit" 
                >
                  <div className="absolute top-0 right-full w-full h-full bg-orange-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                  <span className="relative">Login</span>
                </button>
              </form>
              <div className="text-center">
                <span className="text-xs font-semibold text-gray-900">
                  <span>Do not have an account?</span>
                  <Link
                    className="inline-block ml-1 text-orange-900 hover:text-orange-700"
                    to="/signup"
                  >
                    Sign up
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Login;
