import React, { useEffect, useState } from "react";
import { VerifyCoupon, couponSelector, fetchAllCoupons } from "../../api/coupon";
import { useDispatch, useSelector } from "react-redux";

export default function AddCoupon({ user, total }) {
  const [couponCode, setCouponCode] = useState("");
  const [loading, setLoading] = useState(true); 
  const { allcoupon } = useSelector(couponSelector);

  const dispatch = useDispatch();



  useEffect(() => {
    dispatch(fetchAllCoupons());
  }, [dispatch]);



  const handleInputChange = (e) => {
    setCouponCode(e.target.value);
  };

  const onFinish = (e) => {
    e.preventDefault();

    // Create couponData object
    const couponData = {
      coupon_name: couponCode,
      total: total,
    };

    // If user is available, add user_id to couponData
    if (user) {
      couponData.user_id = user._id;
    }

    // Dispatch the VerifyCoupon action with couponData
    dispatch(VerifyCoupon(couponData));
  };

  return (
    <div>
      <div className="flex ml-4 md:ml-10"  style={{ width: "50px" }}>
        <input
          className="md:block  md:flex-grow  py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
          id="exampleFormControlInput2"
          placeholder="   Enter Coupon Code"
          value={couponCode}
          onChange={handleInputChange}
          disabled={allcoupon?.msg === "Coupon verified successfully"}
         
        />
        <button
          className="bg-[#99154B] hover:bg-[#99154B] rounded-sm  text-white hover:text-white px-2  "
          onClick={onFinish}
          disabled={allcoupon?.msg === "Coupon verified successfully"}
        >
          Apply
        </button>
      </div>
      {allcoupon.map((coupon) => ( <div className="mt-2 ml-4 text-sm md:text-md text-white"><spam >Use Coupon <span className="text-yellow-500">{coupon?.coupon_name}</span>  to get <span className="text-red-400">{coupon?.discount}%</span> discount</spam></div>       ))}
      
      <h1
        className={`text-${
          allcoupon?.msg === "Coupon verified successfully"
            ? "bg-green-500"
            : "red-600"
        }`}
      >
        {allcoupon?.msg}
      </h1>
    </div>
  );
}
