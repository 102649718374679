import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { keyUri } from "../key";

export const initialState = {
  loading: false,
  hasErrors: false,
  products: [],
  allproducts: [],
  currentProduct: null,
  currentOrder: [],
  productOrders: [],
  upsellProduct: [],
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    getProduct: (state) => {
      state.loading = true;
    },
    getProductSuccess: (state, { payload }) => {
      state.loading = false;
      state.products = payload.product;
    },

    getAllProductSuccess: (state, { payload }) => {
      state.loading = false;
      state.allproducts = payload;
    },

    getCurrentProduct: (state, { payload }) => {
      state.loading = false;
      state.currentProduct = payload.product;
    },

    getUpsellProduct: (state, { payload }) => {
      state.loading = false;
      state.upsellProduct = payload.productupsell;
    },

    getProductFailure: (state, { payload }) => {
      state.loading = false;
      state.products = payload;
    },
  },
});

export const {
  getProduct,
  getCurrentProduct,
  getUpsellProduct,
  getProductSuccess,
  getProductFailure,
  getAllProductSuccess,
} = productSlice.actions;

export const productSelector = (state) => state.products;

export default productSlice.reducer;

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const fetchAllProducts = () => async (dispatch) => {
  const key = "product";
  dispatch(getProduct());

  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + "/products");

    dispatch(getAllProductSuccess(data));
  } catch ({ response }) {
    dispatch(getProductFailure());
    // response.data && message.error({ content: response.data.msg, key, duration: 2 });
  }
};

export const fetchOneProduct = (id) => async (dispatch) => {
  const key = "product";
  dispatch(getProduct());

  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + `/products/${id}`);
    dispatch(getCurrentProduct(data));
  } catch ({ response }) {
    dispatch(getProductFailure());
    //   response.data && message.error({ content: response.data.msg, key, duration: 2 });
  }
};

export const fetchOneProductTags = (id) => async (dispatch) => {
  const key = "product";
  dispatch(getProduct());

  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + `/tagproducts/${id}`);
    dispatch(getCurrentProduct(data));
  } catch ({ response }) {
    dispatch(getProductFailure());
    //   response.data && message.error({ content: response.data.msg, key, duration: 2 });
  }
};

export const fetchOneProductUpsell = (id) => async (dispatch) => {
  const key = "product";
  dispatch(getProduct());

  try {
    const { data } = await axios.get(
      keyUri.BACKEND_URI + `/upsell-products/${id}`
    );
    dispatch(getUpsellProduct(data));
  } catch ({ response }) {
    dispatch(getProductFailure());
    //   response.data && message.error({ content: response.data.msg, key, duration: 2 });
  }
};

export const fetchAllcategoryProduct = (id) => async (dispatch) => {
  dispatch(getProduct());
  try {
    const { data } = await axios.get(
      keyUri.BACKEND_URI + `/category-product/${id}`
    );
    dispatch(getProductSuccess(data));
  } catch (error) {
    dispatch(getProductFailure());
  }
};
