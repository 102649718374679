import React from "react";
import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { authenticateSelector, checkAuth } from "../../api/authSlice";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { fetchUserOrder } from "../../api/order";

const UserProfile = () => {
  const dispatch = useDispatch();

  const { user } = useSelector(authenticateSelector);

  useEffect(() => {
    dispatch(fetchUserOrder(user?._id));
  }, [user]);

  useEffect(() => {
    dispatch(checkAuth());
  }, []);

  return (
    <div>
      <Navbar />
      <div className="container mx-auto mt-2">
        <header className="text-center mb-3">
          {user ? (
            <h1 className="text-xl text-[#314387] font-bold capitalize">
              Welcome, {user.user_name}
            </h1>
          ) : (
            <h1 className="text-md text-[#314387] font-bold capitalize">
              Hi,Greeting on your return
            </h1>
          )}
        </header>
        <main className="flex flex-col md:flex-row space-y-4 md:space-y-0">
          {user ? (
            <section className="bg-white rounded-md shadow-md p-4 md:w-1/2">
              <div className="flex">
                <h2 className="text-lg font-semibold mb-4 underline">
                  Profile Information
                </h2>
                <Link to="/editprofile" className="ml-2 mb-2 text-blue-500">
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              </div>
              <p>
                <strong>UserName:</strong> {user.user_name}
              </p>
              <p>
                <strong>Email:</strong> {user.email}
              </p>
              <p>
                <strong>Phone number:</strong> {user.phone_number}
              </p>
              <p>
                <strong>Address:</strong> {user.address}
              </p>
            </section>
          ) : (
            // Render login prompt or redirect to login page
            <p className="text-center">
              Please log in to view your profile.{" "}
              <Link className="text-blue-700" to="/login">
                Login
              </Link>
            </p>
          )}
        </main>
      </div>

      <Footer />
    </div>
  );
};

export default UserProfile;
