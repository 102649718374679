import { createSlice  } from '@reduxjs/toolkit';
import keyUri from '../key'


const cart =  localStorage.getItem('alpxelcart') ?
JSON.parse(localStorage.getItem('alpxelcart')) : []

// const total =  localStorage.getItem('total') ?
// JSON.parse(localStorage.getItem('total')) : 0


let total = cart.reduce((total, num)=>{
    return total + (num.total_price * num.qty)
}, 0)




  

  export const initialState = {

    loading: false,
    hasErrors: false,
    cartProducts:cart,
    subTotal:0,
    total: Number(total),
    isUsedWalletBallance : 0,
    isUsedWallet : false
    
    
}



export const cartSlice = createSlice({
    name:"cart",
    initialState,
    reducers:{

        

       getCartProducts: state =>{
            state.loading = true
        },
        
        getTotal: state =>{       
        state.total = state.cartProducts.reduce((total, crr)=>{   
        return total + (crr.total_price * crr.qty)    
        }, 0)
    
          },



        removeItem: (state, {payload}) =>  {

          

        state.cartProducts = state?.cartProducts.filter(item => {

                
    
                return  item?._id !== payload
            }) 
            state.total = state.cartProducts.reduce((total, crr)=>{
    
                return total + (crr.total_price * crr.qty)
    
            }, 0)
    
            localStorage.setItem('alpxelcart', JSON.stringify(state.cartProducts) )
            // localStorage.setItem('total', JSON.stringify(state.total) )
    
          },


        getCartProductSuccess : (state, {payload}) => {

            state.cartProducts = [...state.cartProducts, payload] 

                 state.loading = false
                 state.hasErrors = false
                 state.total = state.cartProducts.reduce((total, crr)=>{
                     return total + (crr.total_price * crr.qty)
                 }, 0)
                 
                 localStorage.setItem('alpxelcart', JSON.stringify(state.cartProducts) )
                //  localStorage.setItem('total', JSON.stringify(state.total) )
     
             },



        increments:( state, {payload}) =>{

            
            const exist = state.cartProducts.findIndex((item) => item._id === payload.id);

        if(exist !== -1){
            state.cartProducts[exist].qty =  payload.qty          
         }
                 state.loading = false
                 state.hasErrors = false
                 state.total = state.cartProducts.reduce((total, crr)=>{
                     return total + (crr.total_price * crr.qty)
                 }, 0)
                 
                 localStorage.setItem('alpxelcart', JSON.stringify(state.cartProducts) )
                
             },


             
         datetime:( state, {payload}) =>{
           
            const exist = state.cartProducts.findIndex((item) => item._id === payload.id);

                if(exist !== -1){
                    state.cartProducts[exist].time =  payload.time     
                    state.cartProducts[exist].date =  payload.date          
                }

                state.loading = false
                state.hasErrors = false

                localStorage.setItem('alpxelcart', JSON.stringify(state.cartProducts) )
          },




        getCartProductsFailure : state =>{

            state.loading = false
            state.hasErrors = true 
        }

    }
})


export const {
    getCartProductsFailure,
    getCartProductSuccess,
    getCartProducts,
    increments,
    getTotal,
    datetime,
    getDatetime,
    removeItem,
    getWalletBalance
} = cartSlice.actions



export const cartProductSelector = state => state.cart

export default cartSlice.reducer



export const addCartProducts = (data) => async dispatch =>{


    dispatch(getCartProducts())
    
    try {
       
        dispatch(getCartProductSuccess(data))
          
    } catch (error) {
        dispatch(getCartProductsFailure())
  
    }
}





export const datetimeUpdate = (id) => async dispatch =>{

    
        dispatch(datetime(id))
        dispatch(getDatetime())
    
    
    }




export const qtyupdate = (id) => async  dispatch =>{

    dispatch(getCartProducts())

    try {
        dispatch(increments(id))  
        
    } catch (error) {
        dispatch(getCartProductsFailure())
  
    }
}

export const dateupdate = (id) => async  dispatch =>{
    

    dispatch(getCartProducts())

    try {
        dispatch(datetime(id))  
        
    } catch (error) {
        dispatch(getCartProductsFailure())
  
    }
}



export const deleteCartItem = (data) => async dispatch => {
    try {
    

        dispatch(removeItem(data))
        // window.location.reload()
    } catch (error) {
        console.log(error);

    }
};



export const clearCartData = (data) => async  dispatch =>{

    localStorage.removeItem('emmuniticart')
    window.location.reload()

}





