import React from "react";
import Footer from "../../common/Footer";
import Navbar from "../../common/Navbar";

function Terms() {
  return (
    <div>
      <Navbar />
      <section
        class=" max-w-screen-xl min-h-[78vh] px-4 py-6 mx-auto mb-14 "
       
      >
        <h2 class="mb-2 md:mb-5 md:mt-10 text-[20px] md:text-[22px] text-[#314387]  font-bold font-heading">
          Terms and Condition
        </h2>
        <p className="md:text-[16px] text-[14px] text-gray-700 text-justify">
          Welcome to Alpxel! These terms and conditions govern your use of our
          website and the purchase of our products. By accessing our website and
          making a purchase, you agree to comply with these terms. Please read
          them carefully before proceeding.
        </p>

        <p class="mb-2 md:mb-5 md:mt-10 text-[20px] md:text-[22px] text-[#314387] font-bold font-heading">
          Intellectual Property:
        </p>

        <div className="md:text-[16px] text-[14px] text-gray-700 text-justify">
          <p>
            All content on our website, including text, images, graphics, logos,
            and product descriptions, is protected by intellectual property
            rights owned or licensed by Alpxel. You are granted a limited,
            non-exclusive license to access and use the content for personal,
            non-commercial purposes only.
          </p>
        </div>

        <p class="mb-2 md:mb-5 md:mt-10 text-[20px] md:text-[22px] text-[#314387] font-bold font-heading">
          Product Information and Accuracy:
        </p>
        <div className="md:text-[16px] text-[14px] text-gray-700 text-justify">
          <p>
            While we strive to provide accurate and up-to-date information, our
            product descriptions, prices, and availability may be subject to
            change without notice. We are not liable for any errors,
            inaccuracies, or omissions in the content of our website.
          </p>
        </div>

        <p class="mb-2 md:mb-5 md:mt-10 text-[20px] md:text-[22px] text-[#314387] font-bold font-heading">
          Product Use and Disclaimer:
        </p>
        <div className="md:text-[16px] text-[14px] text-gray-700 text-justify">
          <p>
            Our products are intended for their designated purposes as outlined
            in their descriptions. It's important to follow usage instructions
            and consult a healthcare professional before starting any new
            supplement regimen. Alpxel is not responsible for any adverse
            reactions or misuse of products.
          </p>
        </div>

        <p class="mb-2 md:mb-5 md:mt-10 text-[20px] md:text-[22px] text-[#314387] font-bold font-heading">
          Payment and Pricing:
        </p>
        <div className="md:text-[16px] text-[14px] text-gray-700 text-justify">
          <p>
            All prices are listed in [Currency] and are inclusive of applicable
            taxes, unless otherwise stated. Payment is required at the time of
            placing an order. We accept [Payment Methods] for secure and
            convenient transactions.
          </p>
        </div>

        {/* <p class="mb-2 md:mb-5 md:mt-10 text-[20px] md:text-[22px] text-[#314387] font-bold font-heading">
          Shipping and Delivery:
        </p>
        <div className="md:text-[16px] text-[14px] text-gray-700 text-justify">
          <p>
            Please refer to our Shipping and Delivery Policy for information on
            shipping methods, delivery times, and associated costs. Alpxel is
            not responsible for delays caused by unforeseen circumstances or
            third-party carriers. The delivery timeline is within 2 to 10 days.
          </p>
        </div> */}

        <p class="mb-2 md:mb-5 md:mt-10 text-[20px] md:text-[22px] text-[#314387] font-bold font-heading">
          Changes to Terms and Conditions:
        </p>
        <div className="md:text-[16px] text-[14px] text-gray-700 text-justify">
          <p>
            Alpxel reserves the right to update these terms and conditions at
            any time. Any changes will be effective upon posting to our website.
            It's your responsibility to review these terms periodically for
            updates.
          </p>
          <p>
            By using our website and purchasing our products, you agree to abide
            by these terms and conditions. If you have any questions or
            concerns, please contact our customer support team.
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Terms;
