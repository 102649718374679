import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import productReducer from "./products";
import cartReducer from "./cartSlice";
import orderReducer from "./order";
import couponReducer from "./coupon";
import Testimonial from "./testimonial";
import Mobilebanner from "../api/mobilebanner";
import Banner from "../api/banner";


export default configureStore({
  reducer: {
    auth: authSlice,
    products: productReducer,
    cart: cartReducer,
    order: orderReducer,
    coupon: couponReducer,
    testimonial: Testimonial,
    banner: Banner,
    mobbanner: Mobilebanner,
  },
});
