import React, { useEffect, useLayoutEffect } from "react";
import Navbar from "../../common/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProducts, productSelector } from "../../api/products";
import { Link } from "react-router-dom";
import Footer from "../../common/Footer";

function Allproducts() {
  const dispatch = useDispatch();
  const { allproducts } = useSelector(productSelector);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchAllProducts());
  }, [dispatch]);

  return (
    <div>
      <Navbar />
      <section className="py-10 mx-2 md:mx-10 ">
        <div>
        <div className="w-full md:px-32 px-3">
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
    {allproducts.map((item, index) => (
      <Link to={`/individualpro/${item?._id}`} key={index}>
        <div className="w-full mb-8 relative">
          {/* {item?.stock_qty <= 0 && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="bg-red-500 text-white px-6 py-2 rounded-full uppercase font-bold text-xs">
                Out of stock
              </div>
            </div>
          )} */}
          <div className="">
            <a className="mt-6 mb-2">
              <img
                className="mb-5 h-full rounded-lg w-full"
                src={item?.featured_image}
                alt=""
              />
              <h3 className="mb-2 text-xl text-center text-[#314387] font-bold font-heading">
                {item?.product_name}
              </h3>
              <p className="text-xl text-center font-bold font-heading text-gray-700">
                <span className="mb-6 px-2 py-1 text-2xl font-bold font-heading bg-white text-red-500">
                  -{item?.discount}%
                </span>
                <span>Rs {item?.total_price}</span>
              </p>
              <span className="text-sm text-center inline-block w-full text-gray-500 font-semibold font-heading line-through">
                M.R.P: {item?.price}
              </span>
            </a>
            {item?.stock_qty > 0 ? (
              <button className="w-full h-10 mt-5 flex justify-center bg-[#F15925] rounded-full gap-4">
                <span></span>
                <span className="text-white mt-1 text-base font-semibold">
                  Buy Now
                </span>
              </button>
            ) : (
              <div className="w-full h-10 mt-5 flex justify-center">
                <div className="bg-gray-300 w-full text-center text-gray-700 font-bold font-heading py-2 px-10 rounded-full cursor-not-allowed">
                  Out of Stock
                </div>
              </div>
            )}
          </div>
        </div>
      </Link>
    ))}
  </div>
</div>

        </div>
        {/* <div className="text-center">
            <a
              className="inline-block bg-[#5898CE] hover:bg-[#5898CE] text-white font-bold font-heading py-4 px-8 rounded-md uppercase"
             
            >
              Show More
            </a>
          </div> */}
      </section>
      <Footer />
    </div>
  );
}

export default Allproducts;
