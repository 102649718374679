import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";

export const initialState = {
  loading: false,
  hasErrors: false,
  banner: [],
  currentBanner: null,
};

export const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    getBanner: (state) => {
      state.loading = true;
    },

    getBannerSuccess: (state, { payload }) => {
      state.loading = false;
      state.banner = payload;
    },

    getCurrentBanner: (state, { payload }) => {
      state.loading = false;
      state.currentBanner = payload;
    },
    getBannerFailure: (state, { payload }) => {
      state.loading = false;
      state.banner = payload;
    },
  },
});

export const {
  getBanner,
  getBannerSuccess,
  getCurrentBanner,
  getBannerFailure,
} = bannerSlice.actions;

export const bannerSelector = (state) => state.banner;
export default bannerSlice.reducer;

export const fetchAllBanner = () => async (dispatch) => {
  const key = "banner";
  dispatch(getBanner());

  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + "/banner");

    dispatch(getBannerSuccess(data));
  } catch ({ response }) {
    dispatch(getBannerFailure());
  }
};

export const fetchOneBanner = (id) => async (dispatch) => {
  dispatch(getBanner());

  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + `/banner/${id}`);
    dispatch(getCurrentBanner(data));
  } catch ({ response }) {
    dispatch(getBannerFailure());
  }
};

export const createBanner = (values) => async (dispatch) => {
  const key = "banner";
  dispatch(getBanner());

  try {
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/banner",
      values,
      config
    );
    setTimeout(() => {}, 500);
    dispatch(fetchAllBanner());
  } catch ({ response }) {
    dispatch(getBannerFailure());
  }
};

export const deleteBanner = (id) => async (dispatch) => {
  const key = "banner";
  dispatch(getBanner());

  try {
    const { data } = await axios.delete(keyUri.BACKEND_URI + `/banner/${id}`);
    dispatch(fetchAllBanner());
  } catch ({ response }) {
    dispatch(getBannerFailure());
  }
};

export const updateBanner = (id, values) => async (dispatch) => {
  const key = "banner";
  dispatch(getBanner());

  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/banner/${id}`,
      values,
      config
    );
    // window.location.reload()
    dispatch(fetchAllBanner());
  } catch ({ response }) {
    dispatch(getBannerFailure());
  }
};
