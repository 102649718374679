import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/alpxel logo-02.png";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineGift } from "react-icons/ai";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { useSelector } from "react-redux";
import { cartProductSelector } from "../api/cartSlice";
import styled from "styled-components";
import { useEffect } from "react";
import { keyUri } from "../key";
import { useState } from "react";

function Footer() {
  const [navlinks, setNavlinks] = useState(null);
  const { cartProducts } = useSelector(cartProductSelector);

  useEffect(() => {
    const Navlinks = async () => {
      const Links = await fetch(keyUri.BACKEND_URI + "/category");
      const NewLinks = await Links.json();
      setNavlinks(NewLinks);
    };

    Navlinks();
  }, []);

  return (
    <div>
      <section class="pt-10 pb-10 bg-gray-200  ">
        <div class="container mx-auto px-4  mb-2">
          <div class="max-w-6xl mx-auto">
            <div class="flex flex-wrap md:gap-44 -mx-4">
              <div>
                <img className="hidden md:block w-44" src={Logo}></img>
              </div>
              <div class="w-full lg:w-2/3 px-4">
                <div class="flex flex-wrap justify-between -mx-4 -mb-8">
                  <div class="w-full sm:w-1/2 lg:w-auto px-4 mb-8">
                    <h3 class="text-base font-black text-gray-700 mb-6">
                      Information
                    </h3>
                    <ul>
                      <li class="mb-4">
                        <Link
                          class="text-sm font-bold text-gray-700 hover:text-[#314387]"
                          to="/returns"
                        >
                          Returns &amp; Refund
                        </Link>
                      </li>
                      <li class="mb-4">
                        <Link
                          class="text-sm font-bold text-gray-700 hover:text-[#314387]"
                          to="/privacy"
                        >
                          Privacy Policy
                        </Link>
                      </li>
                      <li className="mb-4">
                        <Link
                          class="text-sm font-bold text-gray-700 hover:text-[#314387] "
                          to="/terms"
                        >
                          Terms &amp; Conditions
                        </Link>
                      </li>
                      <li>
                        <Link
                          class="text-sm font-bold text-gray-700 hover:text-[#314387]"
                          to="/shipping"
                        >
                          Shipping &amp; Delivery
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div class="w-full sm:w-1/2 lg:w-auto px-4 mb-8">
                    <h3 class="text-base font-black text-gray-700 mb-6">
                      Quick links
                    </h3>
                    <ul>
                      <li class="mb-4">
                        <Link
                          class="text-sm font-bold text-gray-700 hover:text-[#314387]"
                          to="/"
                        >
                          Home
                        </Link>
                      </li>
                      <li class="mb-4">
                        <Link
                          class="text-sm font-bold text-gray-700 hover:text-[#314387]"
                          to="/aboutus"
                        >
                          About Us
                        </Link>
                      </li>
                      <li class="mb-4">
                        <Link
                          class="text-sm font-bold text-gray-700 hover:text-[#314387]"
                          to="/allproducts"
                        >
                          Products
                        </Link>
                      </li>
                      <li class="mb-4">
                        <Link
                          class="text-sm font-bold text-gray-700 hover:text-[#314387]"
                          to="/gallery"
                        >
                          Gallery
                        </Link>
                      </li>
                      <li class="mb-4">
                        <Link
                          class="text-sm font-bold text-gray-700 hover:text-[#314387]"
                          to="/contactus"
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div class="w-full lg:w-1/3 px-4 mb-6 lg:mb-10">
                    <a class="inline-block mb-16" href="#">
                      <img
                        class="h-7"
                        src="shopal-assets/logos/shopal-logo-gray-900.svg"
                        alt=""
                        width="auto"
                      />
                    </a>
                    <div class="flex flex-col mb-8">
                      <a
                        class="inline-block text-sm font-bold text-gray-700 hover:underline mb-3"
                        
                      >
                        Call us at: +91 8296089323
                      </a>
                      <a
                        class="inline-block text-sm font-bold text-gray-700 hover:underline"
                        // href="mailto:contact@shopal.com"
                      >
                        Email: reachus@alpxel.com
                      </a>
                    </div>
                    <div>
                      <a
                        class="inline-block text-[#314387] hover:text-indigo-500 mr-6"
                        href="#"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewbox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13.6349 20.7272V12.766H16.3583L16.7669 9.66243H13.6349V7.68126C13.6349 6.78299 13.8882 6.17083 15.203 6.17083L16.8771 6.17015V3.39421C16.5876 3.35731 15.5938 3.27271 14.4371 3.27271C12.0217 3.27271 10.3681 4.71878 10.3681 7.37389V9.66243H7.63647V12.766H10.3681V20.7272H13.6349Z"
                            fill="currentColor"
                          ></path>
                          <mask
                            id="mask0_601_10113"
                            maskunits="userSpaceOnUse"
                            x="7"
                            y="3"
                            width="10"
                            height="18"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13.6349 20.7272V12.766H16.3583L16.7669 9.66243H13.6349V7.68126C13.6349 6.78299 13.8882 6.17083 15.203 6.17083L16.8771 6.17015V3.39421C16.5876 3.35731 15.5938 3.27271 14.4371 3.27271C12.0217 3.27271 10.3681 4.71878 10.3681 7.37389V9.66243H7.63647V12.766H10.3681V20.7272H13.6349Z"
                              fill="gray-900"
                            ></path>
                          </mask>
                        </svg>
                      </a>
                      <a
                        class="inline-block text-[#314387] hover:text-indigo-500 mr-6"
                        href="https://www.youtube.com/@alpxelcooking1430"
                      >
                    <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 48 48"
                        fill="#314387"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="24" cy="24" r="20" fill="#314387" />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M35.3005 16.3781C35.6996 16.7772 35.9872 17.2739 36.1346 17.8187C36.9835 21.2357 36.7873 26.6324 36.1511 30.1813C36.0037 30.7261 35.7161 31.2228 35.317 31.6219C34.9179 32.021 34.4212 32.3086 33.8764 32.456C31.8819 33 23.8544 33 23.8544 33C23.8544 33 15.8269 33 13.8324 32.456C13.2876 32.3086 12.7909 32.021 12.3918 31.6219C11.9927 31.2228 11.7051 30.7261 11.5577 30.1813C10.7038 26.7791 10.9379 21.3791 11.5412 17.8352C11.6886 17.2903 11.9762 16.7936 12.3753 16.3945C12.7744 15.9954 13.2711 15.7079 13.8159 15.5604C15.8104 15.0165 23.8379 15 23.8379 15C23.8379 15 31.8654 15 33.8599 15.544C34.4047 15.6914 34.9014 15.979 35.3005 16.3781ZM27.9423 24L21.283 27.8571V20.1428L27.9423 24Z"
                          fill="white"
                        />
                      </svg>{" "}
                      </a>
                      <a
                        class="inline-block text-[#314387] hover:text-indigo-500 mr-6"
                        href="https://instagram.com/alpxel?igshid=MzRlODBiNWFlZA=="
                      >
                        <svg
                          width="24"
                          height="24"
                          viewbox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.6007 2.18176H16.3992C19.3874 2.18176 21.8184 4.61276 21.8182 7.60069V16.3992C21.8182 19.3871 19.3874 21.8181 16.3992 21.8181H7.6007C4.61276 21.8181 2.18188 19.3872 2.18188 16.3992V7.60069C2.18188 4.61276 4.61276 2.18176 7.6007 2.18176ZM16.3993 20.0759C18.4267 20.0759 20.0761 18.4266 20.0761 16.3992H20.076V7.60069C20.076 5.57343 18.4266 3.924 16.3992 3.924H7.6007C5.57343 3.924 3.92412 5.57343 3.92412 7.60069V16.3992C3.92412 18.4266 5.57343 20.076 7.6007 20.0759H16.3993ZM6.85721 12.0001C6.85721 9.16418 9.16425 6.85709 12.0001 6.85709C14.8359 6.85709 17.1429 9.16418 17.1429 12.0001C17.1429 14.8358 14.8359 17.1428 12.0001 17.1428C9.16425 17.1428 6.85721 14.8358 6.85721 12.0001ZM8.62805 11.9999C8.62805 13.8592 10.1408 15.3718 12.0001 15.3718C13.8593 15.3718 15.3721 13.8592 15.3721 11.9999C15.3721 10.1405 13.8594 8.62784 12.0001 8.62784C10.1407 8.62784 8.62805 10.1405 8.62805 11.9999Z"
                            fill="currentColor"
                          ></path>
                          <mask
                            id="mask0_601_10115"
                            maskunits="userSpaceOnUse"
                            x="2"
                            y="2"
                            width="20"
                            height="20"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.6007 2.18176H16.3992C19.3874 2.18176 21.8184 4.61276 21.8182 7.60069V16.3992C21.8182 19.3871 19.3874 21.8181 16.3992 21.8181H7.6007C4.61276 21.8181 2.18188 19.3872 2.18188 16.3992V7.60069C2.18188 4.61276 4.61276 2.18176 7.6007 2.18176ZM16.3993 20.0759C18.4267 20.0759 20.0761 18.4266 20.0761 16.3992H20.076V7.60069C20.076 5.57343 18.4266 3.924 16.3992 3.924H7.6007C5.57343 3.924 3.92412 5.57343 3.92412 7.60069V16.3992C3.92412 18.4266 5.57343 20.076 7.6007 20.0759H16.3993ZM6.85721 12.0001C6.85721 9.16418 9.16425 6.85709 12.0001 6.85709C14.8359 6.85709 17.1429 9.16418 17.1429 12.0001C17.1429 14.8358 14.8359 17.1428 12.0001 17.1428C9.16425 17.1428 6.85721 14.8358 6.85721 12.0001ZM8.62805 11.9999C8.62805 13.8592 10.1408 15.3718 12.0001 15.3718C13.8593 15.3718 15.3721 13.8592 15.3721 11.9999C15.3721 10.1405 13.8594 8.62784 12.0001 8.62784C10.1407 8.62784 8.62805 10.1405 8.62805 11.9999Z"
                              fill="gray-900"
                            ></path>
                          </mask>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-6 border-t-2 border-gray-900">
          <div class="container  mx-auto">
            <div class="max-w-xl mx-auto">
              <div class="sm:flex items-center justify-between -mb-10">
                <div class="mb-5">
                  <p class="text-sm font-bold text-gray-700">
                    &copy; Copyright 2023. All rights reserved by Alpxel
                  </p>
                </div>
                <div class="mb-5">
                  <a class="inline-block mr-5" href="#">
                    <img src="shopal-assets/logos/visa-logo.svg" alt="" />
                  </a>
                  <a class="inline-block mr-5" href="#">
                    <img src="shopal-assets/logos/mastercard-logo.svg" alt="" />
                  </a>
                  <a class="inline-block" href="#">
                    <img src="shopal-assets/logos/paypal-logo.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterWrap className="sticky top-0 z-50">
        <div
          className="flex justify-center items-center mx-auto gap-16 pt-2  footer  bg-[#314387] md:hidden sm:block fixed "
          style={{ fontFamily: "Poppins" }}
        >
          <Link to="/">
            <span className="text-2xl flex items-center justify-center text-white">
              <AiOutlineHome />{" "}
            </span>
          </Link>

          <Link to="/allproducts">
            <span className="text-2xl flex items-center justify-center text-white ">
              <AiOutlineGift />{" "}
            </span>
          </Link>

          <Link to="/cart">
            <span className="text-2xl flex items-center justify-center text-white ">
              <AiOutlineShoppingCart />{" "}
            </span>
            {cartProducts.length > 0 && (
              <div className="badge ">
                <p>{cartProducts.length}</p>
              </div>
            )}
          </Link>

          <Link to="/userprofile">
            <span className="text-2xl flex items-center justify-center text-white ">
              <CgProfile />{" "}
            </span>
          </Link>
        </div>
      </FooterWrap>
    </div>
  );
}

export default Footer;

const FooterWrap = styled.div`

.footer{
    width: 100%;
    height: 3.0rem;
    position: fixed;
    bottom: 0%;
    left:0%
    margin-Top:0px;
    z-index:40
  
}



.dropdown:hover .dropdown-menu {
  display: block;
}




.navbtn {
    li{

        list-style: none;
        height: 2px;
        background-color: grey;
        margin:4px 0;
    }
}

.badge,.notification  {
    position: absolute;
    z-index: auto;
    min-width: 15px;
    height: 15px;
    padding: 0 3px ;
    color: #f9f9f9;
    font-size: 10px;
    line-height: 16px;
    white-space: nowrap;
    text-align: center;
    background: #ff4d4f;
    font-weight: bold;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 0 1px #fff;
    box-shadow: 0 0 0 1px #fff;

}

.badge  {
    transform: translate(14px, -28px)
}

.notification  {
    transform: translate(11px, -26px)
}`;
