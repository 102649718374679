import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";

export const initialState = {
  loading: false,
  hasErrors: false,
  mobbanner: [],
  currentMobbanner: null,
};

export const mobbannerSlice = createSlice({
  name: "mobbanner",
  initialState,
  reducers: {
    getMobbanner: (state) => {
      state.loading = true;
    },

    getMobbannerSuccess: (state, { payload }) => {
      state.loading = false;
      state.mobbanner = payload;
    },

    getCurrentMobbanner: (state, { payload }) => {
      state.loading = false;
      state.currentMobbanner = payload;
    },
    getMobbannerFailure: (state, { payload }) => {
      state.loading = false;
      state.mobbanner = payload;
    },
  },
});

export const {
  getMobbanner,
  getMobbannerSuccess,
  getCurrentMobbanner,
  getMobbannerFailure,
} = mobbannerSlice.actions;

export const mobbannerSelector = (state) => state.mobbanner;
export default mobbannerSlice.reducer;

export const fetchAllMobbanner = () => async (dispatch) => {
  const key = "mobbanner";
  dispatch(getMobbanner());

  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + "/mobile-Banner");
    dispatch(getMobbannerSuccess(data));
  } catch ({ response }) {
    dispatch(getMobbannerFailure());
  }
};

export const fetchOneMobbanner = (id) => async (dispatch) => {
  dispatch(getMobbanner());

  try {
    const { data } = await axios.get(
      keyUri.BACKEND_URI + `/mobile-Banner/${id}`
    );
    dispatch(getCurrentMobbanner(data));
  } catch ({ response }) {
    dispatch(getMobbannerFailure());
  }
};

export const createMobbanner = (values) => async (dispatch) => {
  const key = "mobbanner";
  dispatch(getMobbanner());

  try {
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/mobile-Banner",
      values,
      config
    );
    setTimeout(() => {}, 500);
    dispatch(fetchAllMobbanner());
  } catch ({ response }) {
    dispatch(getMobbannerFailure());
  }
};

export const deleteMobbanner = (id) => async (dispatch) => {
  const key = "mobbanner";
  dispatch(getMobbanner());

  try {
    const { data } = await axios.delete(
      keyUri.BACKEND_URI + `/mobile-Banner/${id}`
    );
    dispatch(fetchAllMobbanner());
  } catch ({ response }) {
    dispatch(getMobbannerFailure());
  }
};

export const updateMobbanner = (id, values) => async (dispatch) => {
  const key = "mobbanner";
  dispatch(getMobbanner());

  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/mobile-Banner/${id}`,
      values,
      config
    );
    dispatch(fetchAllMobbanner());
  } catch ({ response }) {
    dispatch(getMobbannerFailure());
  }
};
