import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { motion, AnimatePresence } from "framer-motion";
import { Link, NavLink, useParams } from "react-router-dom";
import Logo from "../assets/alpxel logo-02.png";

function Mobilenav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropevent, setDropevent] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    id && setIsMenuOpen(false);
  }, [id]);
  return (
    <div>
      <div className="flex items-center justify-between z-20 md:hidden px-2">
        <div className=" sticky top-0 left-0 ">
          {isMenuOpen && (
            <SidebarWrap
              key="first"
              initial={{ x: -250, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -280, opacity: 1 }}
              transition={{
                duration: 0.36,
                type: "tween",
                ease: "easeInOut",
              }}
            >
              <div className="absolute  w-full  ">
                <div
                  className="container  lg:w-4/5 block"
                  style={{ fontFamily: "Figtree" }}
                >
                  <div>
                    <div className="flex gap-0 ">
                      <li className="dropdown inline ml-4  text-start  cursor-pointer">
                        <div className="dropdown-menu absolute hidden h-auto mt-3  z-50">
                          <ul className="block w-full bg-[#FFFF] shadow px-6 py-4 text-indigo-800 rounded"></ul>
                        </div>
                      </li>

                      <Link
                        to="/profile"
                        className="flex py-6 text-indigo-800  text-[20px] font-semibold rounded-lg shrink-0 "
                      >
                        <Link to="/">
                          <img
                            src={Logo}
                            alt="logo"
                            className=" w-28 md:hidden"
                          />
                        </Link>
                      </Link>
                    </div>
                  </div>
                </div>

                <hr />

                <MenuWrap>
                  <nav>
                    <ul
                      className="space-y-2   text-left   text-[18px] text-orange-800"
                      style={{ fontFamily: "Figtree" }}
                    >
                      <li className="nav-item pl-2 font-bold ">
                        <NavLink
                          activeclassName="selected"
                          exact
                          to="/"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          Home{" "}
                        </NavLink>
                      </li>

                      <li className="nav-item pl-2">
                        <div>
                          <li>
                            <Link
                              id="droptoggle"
                              to="#"
                              style={{
                                backgroundColor: dropevent ? "#f5f5f5" : "#fff",
                                color: dropevent
                                  ? "var(--brandColor)"
                                  : "text-teal-900",
                              }}
                              onClick={() => setDropevent(!dropevent)}
                              exact
                            >
                              <b> Previous Show</b>
                              {dropevent ? (
                                <MdKeyboardArrowUp />
                              ) : (
                                <MdKeyboardArrowDown />
                              )}
                            </Link>
                            {dropevent && (
                              <div className="dropdown_menu">
                                <ul>
                                  <li>
                                    <Link
                                      to="/page2023"
                                      onClick={() => setIsMenuOpen(false)}
                                    >
                                      2023
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/"
                                      onClick={() => setIsMenuOpen(false)}
                                    >
                                      2022
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/events2021"
                                      onClick={() => setIsMenuOpen(false)}
                                    >
                                      2021
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/event2020"
                                      onClick={() => setIsMenuOpen(false)}
                                    >
                                      2020
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/page2019"
                                      onClick={() => setIsMenuOpen(false)}
                                    >
                                      2019
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/events2018"
                                      onClick={() => setIsMenuOpen(false)}
                                    >
                                      2018
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </li>
                        </div>
                      </li>

                      {/* <li className="nav-item pl-2 font-bold">
                          <NavLink
                            to="/"
                            onClick={() => setIsMenuOpen(false)}
                            activeclassName="selected"
                          >
                            Previous Show
                          </NavLink>
                        </li> */}

                      <li className="nav-item pl-2 font-bold">
                        <NavLink
                          to="/upcomingshow"
                          onClick={() => setIsMenuOpen(false)}
                          activeclassName="selected"
                        >
                          Upcoming Events
                        </NavLink>
                      </li>

                      <li className="nav-item pl-2 font-bold">
                        <NavLink
                          to="/gallery"
                          onClick={() => setIsMenuOpen(false)}
                          activeclassName="selected"
                        >
                          Gallery
                        </NavLink>
                      </li>

                      <li className="nav-item pl-2 font-bold">
                        <NavLink
                          to="/blogs"
                          onClick={() => setIsMenuOpen(false)}
                          activeclassName="selected"
                        >
                          Blogs
                        </NavLink>
                      </li>

                      <li className="nav-item pl-2 font-bold">
                        <NavLink
                          to="/contact"
                          onClick={() => setIsMenuOpen(false)}
                          activeclassName="selected"
                        >
                          Contact Us
                        </NavLink>
                      </li>
                    </ul>
                  </nav>
                </MenuWrap>
              </div>
            </SidebarWrap>
          )}
          <div>
            <AnimatePresence>
              {isMenuOpen && (
                <BackdropWrap
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    duration: 0.36,
                    type: "tween",
                    ease: "easeInOut",
                  }}
                  onClick={() => setIsMenuOpen(false)}
                />
              )}
            </AnimatePresence>
          </div>

          <div></div>
        </div>
      </div>
    </div>
  );
}

export default Mobilenav;

const Wrapper = styled.section`
  li > ul {
    transform: translatex(100%) scale(0);
  }
  li:hover > ul {
    transform: translatex(101%) scale(1);
  }
  li > button svg {
    transform: rotate(-90deg);
  }
  li:hover > button svg {
    transform: rotate(-270deg);
  }

  .group:hover .group-hover\:scale-100 {
    transform: scale(1);
  }
  .group:hover .group-hover\:-rotate-180 {
    transform: rotate(180deg);
  }
  .scale-0 {
    transform: scale(0);
  }
  .min-w-32 {
    min-width: 8rem;
  }
`;

const BackdropWrap = styled(motion.div)`
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 200vh;
  background-color: #0a0a0a05;
`;

const SidebarWrap = styled(motion.aside)`
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;
  height: 100vh;
  background-color: #ffffff;
  z-index: 50;
`;

const MenuWrap = styled.div`
  .dropdown_menu {
    text-align: left;
    /* height: 167px ; */
    max-height: 167px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f5f5f5;

    svg {
      font-size: 1rem;
      margin-right: 0.2rem;
    }
  }

  #subdroptoggle {
    background-color: white;
    font-size: 0.9rem;
    font-weight: 600;
    &:hover {
      background-color: #88448a;
      color: white;
    }
  }

  ul {
    padding-bottom: 0.7px;
    background-color: #f5f5f5;
    li {
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        margin-bottom: 0.1rem;
        transition: 0.3s;

        &:hover {
          background-color: #408080;
          color: white;
        }
      }

      list-style: none;
      background-color: white;
      /* color:; */
      cursor: pointer;

      svg {
        font-size: 1.3rem;
      }

      .selected {
        background-color: #88448a;
        color: white;
        width: 100%;
        display: block;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.5rem 0.75rem 0.5rem 0.9rem;
        margin-bottom: 0.3rem;
      }
    }
  }
`;
