import React from "react";

function Section5() {
  return (
    <div>
      <div className="w-full mb-3 bg-[#364F92] text-lg font-bold text-white py-6 uppercase">
        <marquee behaviour="scroll" direction="left" scrollamount="10">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cookware for health &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Premium quality Cookware&nbsp;&nbsp;&nbsp;&nbsp;Premium quality Cookware&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cookware for health &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Premium quality Cookware&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cookware for health &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Premium quality Cookware&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cookware for health &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Premium quality Cookware&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cookware for health &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Premium quality Cookware&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cookware for health &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Premium quality Cookware&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cookware for health &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Premium quality Cookware&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cookware for health &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Premium quality Cookware&nbsp;&nbsp;&nbsp;&nbsp;
        </marquee>
      </div>
    </div>
  );
}

export default Section5;
