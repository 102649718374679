import React from "react";
import Footer from "../../common/Footer";
import Navbar from "../../common/Navbar";

function Privacypol() {
  return (
    <div>
      <Navbar />
      <section
        class=" max-w-screen-xl min-h-[78vh] px-4 py-6 mx-auto text-justify mb-14"
       
      >
        <h2 class="mb-2 md:mb-5 md:mt-10 text-[20px] md:text-[22px] text-[#314387] font-bold font-heading">
          Privacy policy
        </h2>

        <div className="md:text-[16px] text-[14px] text-gray-700">
          <p>
            At Alpxel, we are committed to safeguarding your privacy and
            ensuring the security of your personal information. This Privacy
            Policy outlines how we collect, use, and protect the data you
            provide while interacting with our website and purchasing our
            products.
          </p>

          <p>
            Note down that the privacy policy is subject to the terms and
            conditions according to the Elegant Chocolates & Flowers. Elegant
            Chocolates & Flowers highly recommend that before proceeding for any
            of the registration parts or before entering any of your
            information, read out the terms and conditions of use.{" "}
          </p>
        </div>

        <p class="mb-2 md:mb-5 md:mt-10 text-[20px] md:text-[22px] text-[#314387] font-bold font-heading">
          Information We Collect:
        </p>
        <div className="md:text-[16px] text-[14px] text-gray-700">
          <p>
            We may collect various types of personal information, including but
            not limited to your name, contact details, billing and shipping
            addresses, payment information, and browsing behavior on our
            website. We may also collect non-personal information such as
            demographic data and website usage statistics.
          </p>
        </div>

        <p class="mb-2 md:mb-5 md:mt-10 text-[20px] md:text-[22px] text-[#314387] font-bold font-heading">
          How We Use Your Information:
        </p>
        <div className="md:text-[16px] text-[14px] text-gray-700">
          <p>
            We use the information collected to process your orders, provide
            customer support, personalize your shopping experience, and improve
            our products and services. Your contact information may be used to
            send you updates, promotional offers, and relevant communications,
            unless you choose to opt out
          </p>
        </div>

        <p class="mb-2 md:mb-5 md:mt-10 text-[20px] md:text-[22px] text-[#314387] font-bold font-heading">
          Data Security:
        </p>
        <div className="md:text-[16px] text-[14px] text-gray-700">
          <p>
            We employ industry-standard security measures to protect your data
            from unauthorized access, disclosure, alteration, or destruction. We
            use secure encryption technologies to safeguard sensitive
            information, and our employees adhere to strict privacy policies.
          </p>
        </div>

        <p class="mb-2 md:mb-5 md:mt-10 text-[20px] md:text-[22px] text-[#314387] font-bold font-heading">
          Changes to Privacy Policy:
        </p>
        <div className="md:text-[16px] text-[14px] text-gray-700">
          <p>
            We may update our Privacy Policy from time to time to reflect
            changes in our practices and services. We encourage you to review
            the policy periodically to stay informed about how we protect your
            information.
          </p>
          <p>
            By using our website and purchasing our products, you consent to the
            terms outlined in this Privacy Policy. If you have any questions or
            concerns about your privacy or data security, please reach out to
            our customer support team.
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Privacypol;
