import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authenticateSelector, fetchUserRegister } from "../api/authSlice";
import { toast } from "react-toastify";
import { couponSelector, fetchAllCoupons } from "../api/coupon";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Signup() {
  const nav = useNavigate();
  const { isAuthenticate } = useSelector(authenticateSelector);
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { allcoupon } = useSelector(couponSelector);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // console.log(allcoupon);

  useEffect(() => {
    dispatch(fetchAllCoupons());
  }, [dispatch]);

  const [formData, setFormData] = useState({
    user_name: "",
    phone_number: "",
    email: "",
    // address: "",
    password: "",
    confirm_password: "",
  });
  // console.log(formData);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    // Check if the input fields for passwords (password or confirm_password) have changed
    if (name === "password" || name === "confirm_password") {
      // Update the state to reflect whether the passwords match or not
      setPasswordMismatch(
        name === "confirm_password" && formData.password !== value
      );
    }
  };

  useEffect(() => {
    if (isAuthenticate) {
      nav("/");
    } else {
      nav("/signup");
    }
  }, [isAuthenticate]);
  const resetForm = () => {
    setFormData({
      fullName: "",
      Phonenumber: "",
      email: "",
      // address: "",
      // pincode: "",
      password: "",
      confirm_password: "",
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Dispatch the user registration
    dispatch(fetchUserRegister(formData));
    // .then(() => {
    //   toast.success("User created successfully!", {
    //     position: "top-center",
    //     autoClose: true,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     theme: "light",
    //   });
    // })
    // .catch((error) => {
    //   console.error("Registration failed:", error);
    // });
    // Reset the form
    resetForm();
    setIsSubmitted(true);
  };

  const validatePhoneNumber = () => {
    // Use a regular expression to check if the phone number consists of exactly 10 digits
    const phoneRegex = /^\d{10}$/;

    if (!phoneRegex.test(formData.phone_number)) {
      return "Phone number must be 10 digits.";
    }

    return null; // No validation error
  };

  const toggleShowPassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <Navbar className="sticky top-0" />
      <section className="relative     overflow-hidden">
        <div className="relative     mx-auto">
          <img
            className=" hidden w-[2000px] md:block absolute  top-0 left-0 object-contain  "
            src="https://wallpapercave.com/wp/4lImLyA.jpg"
            alt=""
          />
          <div className="relative container  mx-auto ">
            <div className="max-w-lg md:max-w-md py-2 px-6 xs:px-12 lg:px-10 mx-auto bg-white rounded-xl shadow-lg">
              <h3 className="font-heading text-3xl text-gray-900 font-semibold mb-4">
                Create your account
              </h3>

              <form action="" onSubmit={handleSubmit}>
                {/* <div className="mb-6">
                <label className="block mb-1.5 text-sm text-gray-900 font-semibold" htmlFor="">Name</label>
                <input className="w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 focus:border-purple-500 focus:outline-purple rounded-lg" type="text" placeholder="Stefano Bojarski" />
              </div> */}
                <div className="mb-6">
                  <label
                    className="block mb-1.5 text-sm text-gray-900 font-semibold"
                    htmlFor=""
                  >
                    Full Name
                  </label>
                  <input
                    value={formData.user_name}
                    onChange={handleInputChange}
                    required
                    className="w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 focus:border-purple-500 focus:outline-purple rounded-lg"
                    type="text"
                    name="user_name"
                  />
                </div>
                <div className="mb-6">
                  <label
                    className="block mb-1.5 text-sm text-gray-900 font-semibold"
                    htmlFor="phone_number"
                  >
                    Phone number
                  </label>
                  <input
                    value={formData.phone_number}
                    onChange={handleInputChange}
                    className={`w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border ${
                      validatePhoneNumber()
                        ? "border-red-500"
                        : "border-gray-200"
                    } focus:border-purple-500 focus:outline-purple rounded-lg`}
                    type="text"
                    name="phone_number"
                    id="phone_number"
                  />
                  {/* Display a validation error message if applicable */}
                  {validatePhoneNumber() && (
                    <p className="text-red-500 text-sm mt-1">
                      {validatePhoneNumber()}
                    </p>
                  )}
                </div>

                <div className="mb-6">
                  <label
                    className="block mb-1.5 text-sm text-gray-900 font-semibold"
                    htmlFor=""
                  >
                    Email
                  </label>
                  <input
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    className="w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 focus:border-purple-500 focus:outline-purple rounded-lg"
                    type="email"
                    placeholder="example@gmail.com"
                    name="email"
                  />
                </div>

                {/* <div className="mb-6">
                  <label
                    className="block mb-1.5 text-sm text-gray-900 font-semibold"
                    htmlFor=""
                  >
                    Address
                  </label>
                  <input
                    value={formData.address}
                    onChange={handleInputChange}
                    required
                    className="w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 focus:border-purple-500 focus:outline-purple rounded-lg"
                    type="address"
                    name="address"
                  />
                </div>
                <div className="mb-6">
                  <label
                    className="block mb-1.5 text-sm text-gray-900 font-semibold"
                    htmlFor=""
                  >
                    Pincode
                  </label>
                  <input
                    value={formData.pincode}
                    onChange={handleInputChange}
                    required
                    className="w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 focus:border-purple-500 focus:outline-purple rounded-lg"
                    type="number"
                    name="pincode"
                  />
                </div> */}

                <div className="mb-6">
                  <label
                    className="block mb-1.5 text-sm text-gray-900 font-semibold"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <div className="relative">
                    <input
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                      className={`w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border ${
                        passwordMismatch ? "border-red-500" : "border-gray-200"
                      } focus:border-purple-500 focus:outline-purple rounded-lg`}
                      type={showPassword ? "text" : "password"}
                      name="password"
                    />
                    <button
                      onClick={toggleShowPassword}
                      className="absolute top-1/2 right-0 mr-3 transform -translate-y-1/2 inline-block hover:scale-110 transition duration-100"
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                </div>

                <div className="mb-6">
                  <label
                    className="block mb-1.5 text-sm text-gray-900 font-semibold"
                    htmlFor="confirm_password"
                  >
                    Confirm Password
                  </label>
                  <div className="relative">
                    <input
                      value={formData.confirm_password}
                      onChange={handleInputChange}
                      required
                      className={`w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border ${
                        passwordMismatch ? "border-red-500" : "border-gray-200"
                      } focus:border-purple-500 focus:outline-purple rounded-lg`}
                      type="password"
                      name="confirm_password"
                    />
                    <button className="absolute top-1/2 right-0 mr-3 transform -translate-y-1/2 inline-block hover:scale-110 transition duration-100">
                      <img alt="" />
                    </button>
                  </div>
                  {/* Display error message if passwords don't match */}
                  {passwordMismatch && (
                    <p className="text-red-500 text-sm mt-1">
                      Passwords do not match.
                    </p>
                  )}
                </div>
                {/* <div className="flex mb-6 items-center">
                <input type="checkbox" value="" id="" />
                <label className="ml-2 text-xs text-gray-800" htmlFor="">Remember me</label>
              </div> */}
                <button
                  className="relative group block w-full mb-6 py-3 px-5 text-center text-sm font-semibold text-orange-50 bg-[#F15925] rounded-full overflow-hidden"
                  type="submit"
                >
                  <div className="absolute top-0 right-full w-full h-full bg-orange-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                  <span className="relative">Create account</span>
                </button>

                <div className="text-center">
                  <span className="text-xs font-semibold text-gray-900">
                    <span>Already have an account?</span>
                    <Link
                      className="inline-block ml-1 text-orange-900 hover:text-orange-700"
                      to="/login"
                    >
                      Sign in
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Signup;
